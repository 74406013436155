<template>
  <div class="terminals">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-terminal-list')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @importBtnClick="handleImport"
      @editBtnClick="handleEditData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #extraHandler>
        <el-button
          size="mini"
          icon="el-icon-upload"
          @click="handlePushTerminal"
        >
          Push
        </el-button>
        <el-button
          class="custom-button"
          type="success"
          size="mini"
          icon="el-icon-top-right"
          @click="handleImportTerminal"
        >
          Import Terminal
        </el-button>
        <el-button
          type="success"
          size="mini"
          icon="el-icon-top-right"
          @click="handleImportParam"
        >
          Import Param
        </el-button>
      </template>
      <template #onlineStatus="scope">
        <div class="status-wrap" v-if="scope.row.online">
          <div class="circle online-circle"></div>
          Online
        </div>
        <div class="status-wrap" v-else>
          <div class="circle offline-circle"></div>
          Offline
        </div>
      </template>
      <template #otherHandler="scope">
        <el-button
          v-if="isConnect"
          class="opt-btn"
          icon="el-icon-connection"
          size="mini"
          plain
          @click="handleConnection(scope.row)"
          :loading="getLoadingState(scope.row.id)"
          >Connect</el-button
        >
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleEnableClick(scope.row, 'disable')"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row, 'enable')"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
    <!-- 远程链接 -->
    <remote-client-dialog
      ref="remoteClientDialogRef"
      :currentTerminalSession="currentTerminalSession"
    />
    <!-- 抽屉 存放要配置的template -->
    <el-drawer
      :size="'65%'"
      v-model="isShowDrawer"
      title="Configure Terminal Template"
      :direction="direction"
      :before-close="handleBeforeCloseDrawer"
    >
      <div class="drawer-content">
        <el-row>
          <el-col :span="24" style="padding: 18px; height: 500px">
            <el-select
              v-model="currentTemplate"
              @change="handleSelectionChange"
            >
              <el-option
                v-for="item in templates"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <LTable
              :maxHeight="600"
              ref="parameterList"
              :listDataOptions="currentParameterData"
              :ids="currentParameterIds"
              :types="currentParameterTypes"
              :templateType="templateType"
              :isShowCheckItem="false"
            ></LTable>
            <div style="text-align: right">
              <el-button
                style="margin-top: 15px"
                type="primary"
                size="small"
                icon="el-icon-upload"
                @click="handlePushClick"
              >
                Push
              </el-button>
              <el-button
                style="margin-top: 15px"
                type="warning"
                size="small"
                icon="el-icon-refresh"
                @click="handleResetTemplate"
              >
                Reset
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
    <!-- 远程链接再次确认框 -->
    <el-dialog
      title="Connect Confirmation"
      :modelValue="isShowConnect"
      width="30%"
      center
      :show-close="false"
      :before-close="handleClose"
    >
      <p>
        <span> Current SN --- </span>
        <span style="font-weight: bold; font-size: 20px; color: skyblue">
          {{ currentTerminalId }}</span
        >
      </p>
      <el-input
        v-model="randomCode"
        style="width: 80%; margin-top: 20px"
        placeholder="Please enter the remote code"
      ></el-input>
      <template v-slot:footer>
        <el-button
          ref="acceptBtn"
          size="small"
          type="primary"
          @click="handleConnectTerminal"
          >Confirm</el-button
        >
        <el-button size="small" @click="handleCloseDialog">Cancel</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  watch
} from 'vue'
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/terminal.form'
import { contentTableConfig } from './config/terminal.table'
import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'
import { useStore } from 'vuex'

import remoteClientDialog from '@/components/RemoteClientDialog/remote-client-dialog.vue'
import { cloneDeep } from 'lodash'
import LTable from './dynamic-table2.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/i18n'
const remoteClientDialogRef = ref(null)

const store = useStore()
const randomCode = ref('')
const confirmVisible = ref(false)
const loading = ref(false)

const intervalId = ref(null)
const intervalId2 = ref(null)
const intervalId3 = ref(null)
const currentTerminalSession = ref(null)
// const currentSessionStatus = ref('')
const currentTerminalId = ref('')
const isShowDrawer = ref(false)
const handleCloseDialog = () => {
  isShowConnect.value = false
}
const { t } = i18n.global
const templates = ref([])
const currentTemplate = ref('')
const currentParameterData = ref([])
const currentParameterIds = ref([])
const currentParameterTypes = ref([])
const handleSelectionChange = (id) => {
  const currentTemplate = templates.value?.find((item) => item.id === id)
  const object = JSON.parse(currentTemplate?.parameter)
  currentParameterData.value = Object.keys(
    JSON.parse(currentTemplate?.parameter)
  )?.map((item) => {
    return {
      key: item ? item?.split('@')?.[1] : item,
      value: object[item]
    }
  })
  currentParameterIds.value = currentTemplate?.paramId?.split(',')
  currentParameterTypes.value = currentTemplate?.paramType?.split(',')
}

onMounted(async () => {
  // 获取Parameter Templates参数
  templates.value = await store.dispatch('globalParams/getParamTemplateList')

  // 开启定时器 遍历获取
  // intervalId3.value = setInterval(autoGetParams, 5000) // 5000毫秒，即5秒钟
})
const handleConnectTerminal = () => {
  ElMessage.info('Remote code is error')
}
const parameterList = ref(null)
const selectedItems = ref([])
watch(
  () => parameterList?.value?.selectedListData,
  (newVal) => {
    if (newVal) {
      selectedItems.value = newVal?.map((item) => item.key)
    }
  }
)

onUnmounted(() => {
  // 组件销毁时清除定时器
  clearInterval(intervalId.value)
  clearInterval(intervalId2.value)
  clearInterval(intervalId3.value)
})

watch(
  // 监听store中的是否重连标识 重跑流程
  () => store.state.terminal.isContinueRemote,
  (newVal) => {
    if (newVal) {
      // 提交mutation 将重连标识重置为false
      store.commit('terminal/updateReconnectRemoteStatus')
      loading.value = false
      // 打开确认申请对话框
      confirmVisible.value = true
    }
  }
)

const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

// 表格基础配置项
const tableOptions = {
  moduleName: 'terminal', // 所属的模块
  pageName: 'terminals', // 所属的页面
  contentTableConfig,
  isShowDeleteSlot: true,
  // isShowImportSlot: true,
  pageAction: 'terminal/getTerminalListData', // 获取表格的Action
  deleteRecordAction: 'terminal/handleDeleteTerminal',
  deleteSelectionAction: 'terminal/deleteMultiTerminal', // 删除record的Action
  pageCountGetter: 'terminal/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'terminal/pageListData' // 获取表格列表数据getters
}

// 推送终端
const handlePushTerminal = async (record) => {
  // 获取所选中的终端
  const selectedTerminals = pageContentRef.value?.getCurrentSectionList()
  if (
    !selectedTerminals ||
    (Array.isArray(selectedTerminals) && selectedTerminals?.length === 0)
  ) {
    ElMessage.error('Please select the terminal first')
    return
  }
  ElMessageBox.confirm(
    'Are you sure to push parameters to the terminal(s)?',
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      const res = await store.dispatch('terminal/terminalPushParam', {
        // 遍历获取所选中的terminals的SN
        sn: selectedTerminals?.map((item) => item.sn)
      })
      if (res.data.code === 200) {
        ElMessage.success(res.data.msg)
      } else {
        ElMessage.error(res.data.msg)
      }
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: 'Operation Cancelled.'
      })
    })
}

const tableOptionsConfig = computed(() => tableOptions)
const handlePushClick = async () => {
  if (!currentTemplate.value) {
    ElMessage.info('Please select the template first')
    return
  }
  ElMessageBox.confirm(
    'Are you sure to push the template?',
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      // 调用校验函数 判断是否校验通过
      const result = await parameterList.value?.validateAllForms()
      if (!result) {
        ElMessage.error('Please check the fields in the parameter list')
        return
      }
      const parameters = parameterList.value?.parameterList
      const obj = {}
      parameters.forEach((item, index) => {
        const key = item.id + '@' + item.key
        obj[key] = item.value
      })
      const parameter = JSON.stringify(obj)
      const res = await store.dispatch('terminal/terminalPushParam', {
        sn: selectedRows.value?.map((item) => item.sn),
        parameter,
        templateId: currentTemplate.value
      })

      if (res.data.code === 200) {
        ElMessage.success(res.data.msg)
        // 关闭抽屉
        isShowDrawer.value = false
      } else {
        ElMessage.error(res.data.msg)
      }
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: 'Cancel the push operation'
      })
    })

  // todo 操作提示
  // 操作成功 关闭抽屉
}
// 获取页面操作的权限
const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)
const isConnect = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'connect'
)

const isNormalStatus = (status) => {
  return status === '00' || status === '01'
}

const handleEnableClick = async (row, type) => {
  const url =
    type === 'enable' ? 'handleEnableTerminal' : 'handleDisableTerminal'
  handleMessageCommit(`terminal/${url}`, row.id).then(() => {
    pageContentRef.value.getPageData()
  })
}

const handleClose = () => {
  isShowDrawer.value = false
}

const currentLoadingBtnId = ref('')
// 辅助函数，用来获取某个按钮的 loading 状态
const getLoadingState = (buttonId) => {
  if (buttonId === currentLoadingBtnId.value) {
    return loading.value
  } else {
    return false
  }
}

const isShowConnect = ref(false)

const handleConnection = async (row) => {
  // 打开对话框
  isShowConnect.value = true

  currentTerminalId.value = row.sn
}

const pageContentRef = ref('')
const pageSearchRef = ref('')

const formateData = (data) => {
  const formateObject = cloneDeep(data)
  formateObject.tenantId = data.tenantId ? data.tenantId.join(',') : ''
  return formateObject
}

const handleQueryClick = (queryInfo) => {
  pageContentRef.value.getPageData(formateData(queryInfo))
}

const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleEditData = (id) => {
  router.push(`/terminal/edit/${id}`)
}
const selectedRows = ref([])

const handleViewData = (id) => {
  router.push(`/terminal/view/${id}`)
}

const handleNewData = () => {
  router.push(`/terminal/new`)
}
const handleImportTerminal = () => {
  router.push(`/terminal/new/import`)
}
const handleImportParam = () => {
  router.push(`/terminal/new/param`)
}

const handleBeforeCloseDrawer = () => {
  currentTemplate.value = ''
  currentParameterData.value = []
  isShowDrawer.value = false
}

const handleResetTemplate = () => {
  currentTemplate.value = ''
  currentParameterData.value = []
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formateData(formData))
}
</script>

<style scoped>
.custom-button {
  /* 正常状态的颜色 */
  background-color: #68c3df; /* 自定义背景色 */
  border-color: #68c3df; /* 自定义边框色 */
  color: #ffffff; /* 自定义文本色 */
}

.custom-button:hover {
  /* 悬浮状态的颜色 */
  background-color: #98d6e9; /* 自定义悬浮背景色 */
  border-color: #98d6e9; /* 自定义悬浮边框色 */
  color: #ffffff; /* 可以根据需要调整文本色 */
}
.custom-dialog {
  border-radius: 10px; /* 圆角 */
  background-color: rgba(0, 0, 0, 0.7) !important; /* 半透明黑色背景 */
  padding: 20px; /* 内边距 */
  border-radius: 10px; /* 圆角边框 */
}

.dialog-title {
  color: #333; /* 标题颜色 */
  font-size: 18px;
}

.loading-wrapper {
  margin-top: 65px;
  text-align: center;
  padding: 20px;
}

.loading {
  display: inline-block;
  width: 60px; /* 加载动画的大小 */
  height: 60px; /* 加载动画的大小 */
  position: relative;
}

.loading span {
  position: absolute;
  width: 16px; /* 加载动画元素的大小 */
  height: 16px; /* 加载动画元素的大小 */
  background: #f3214f; /* 加载动画的颜色 */
  border-radius: 50%;
  animation: loading 1.6s ease-in-out infinite;
}

.loading span:nth-child(1),
.loading span:nth-child(5) {
  top: 0;
  left: 50%;
  animation-delay: -0.8s;
}

.loading span:nth-child(2),
.loading span:nth-child(6) {
  top: 25%;
  right: 0;
  animation-delay: -0.6s;
}

.loading span:nth-child(3),
.loading span:nth-child(7) {
  top: 50%;
  right: 25%;
  animation-delay: -0.4s;
}

.loading span:nth-child(4),
.loading span:nth-child(8) {
  top: 75%;
  left: 0;
  animation-delay: -0.2s;
}

@keyframes loading {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 15px;
  display: block;
  color: #666; /* 加载文本颜色 */
}

.content-wrapper {
  /* 自定义内容样式 */
  height: 400px !important;
}

/* 使用样式穿透来修改 el-dialog 高度 */
::v-deep .el-dialog__body {
  height: 160px !important;
  background-color: rgba(0, 0, 0, 0.1) !important; /* 半透明黑色背景 */
}

::v-deep .el-dialog__header {
  background-color: rgba(0, 0, 0, 0.1) !important; /* 半透明黑色背景 */
}

.status-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}
.online-circle {
  background-color: green;
}
.offline-circle {
  background-color: #ff0000;
}
</style>
